import $ from "jquery";

$(function () {
// Freshdesk live chat
    function initFreshChat() {
        const host = "https://wchat.eu.freshchat.com";
        const token = "4a7fdf98-d12f-49a6-aa99-fd0fb7d5f82a";

        let tags;
        if (userIdentity != null && section != null) {
            const roles = userIdentity.snapplifyengagerole.split("/").map(it => section + '.' + it.toLowerCase());
            tags = [project, section].concat(roles);
        } else if (section != null) {
            tags = [project, section]
        } else {
            tags = [project]
        }

        const faqTags = {
            tags: tags,
            filterType: 'category'
        };

        const config = {
            content: {
                headers: {
                    channel_response: {
                        offline: ' '
                    }
                }
            }
        };

        window.fcWidget.init({
            token, host, config, tags, faqTags
        });

        if (userIdentity != null) {
            let authenticated = userIdentity?.id ? 'true' : 'false';
            window.fcWidget.user.setProperties({
                // First, Last and Email are not strings, but properties of the user object
                firstName: userIdentity.firstname,
                lastName: userIdentity.lastname,
                email: userIdentity.email,
                "user_isAuthenticated": authenticated,
                "user_username": userIdentity.snapplifyusername,
                "customer_name": userIdentity.snapplifycustomer,
                "customer_URL": window.location,
                "customer_country": userIdentity.country
            });
        }
    }

    function initialize(i, t) {
        let e;
        i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.eu.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e));
    }

    function initiateCall() {
        initialize(document, "Freshdesk Messaging-js-sdk");
    }

    window.addEventListener ? window.addEventListener("load", initiateCall, !1) : window.attachEvent("load", initiateCall, !1);
});
